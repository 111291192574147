import React from "react";
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby";

const DonateValidate = () => {
  const possibleNumbers = [];

  for (let i = 1; i <= 5000; i++) {
    possibleNumbers.push(i);
  }

  return (
    <Layout>
      <div>
        {possibleNumbers.map((item) => {
          return (
            <>
              <button
                className={"snipcart-add-item"}
                data-item-name={`Open Amount Donation for ${item}`}
                data-item-id={`subscription-onetime-${item}`}
                data-item-url={`https://youthmissionoutreach.org/donate-validation`}
                data-item-price={item}
                data-item-shippable={false}
                data-item-description="open amount donation"
              />
            </>
          );
        })}
      </div>
    </Layout>
  );
};

export default DonateValidate;
